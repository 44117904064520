import React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { alpha, IconButton as MUIIconButton, Stack, styled, Typography } from "@mui/material";
import NumberField from "components/elements/NumberField";
import { Credit } from "components/icons/components/Credit";
import { renderNumber } from "utils/formatter";

export interface ItemProps {
  amount: number;
  value: number;
  onChange: (value: number) => void;
}

const min = 0;
const max = 100;

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  width: "30px",
  height: "30px",

  "&.Mui-disabled": {
    color: theme.palette.common.white,
    backgroundColor: "#C4C4C4",
  },
  "&:hover:not(.Mui-disabled)": {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

const Item: React.FC<ItemProps> = ({ amount, value, onChange }) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof event.target.value === "number" && Number(event.target.value) < min) event.target.value = `${min}`;
    onChange(event.target.value === "" ? 0 : Number(event.target.value));
  };


  const handleBlur = () => {
    if (value === undefined) return;

    if (value < min) onChange(min);
    else if (value > max) onChange(max);
  };

  const isDisabledIncrease = value ? value >= max : false;
  const handleIncrease = () => onChange(typeof value === "number" ? value + 1 : min);

  const isDisabledDecrease = value ? value <= min : true;
  const handleDecrease = () => onChange(typeof value === "number" ? value - 1 : min);

  return (
    <Stack justifyContent={"space-between"} direction={"row"}>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant="h6" fontWeight={600} color={"gray"}>
          {renderNumber(amount)}
        </Typography>
        <Credit sx={{ marginLeft: "4px" }} />
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"center"} alignContent={"center"}>
        <NumberField
          value={value}
          size="small"
          autoComplete="off"
          onlyInteger
          onlyPositive
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: "number",
          }}
          sx={{ width: "60px" }}
        />
        <Stack direction={"row"} spacing={1}>
          <IconButton onClick={handleDecrease} disabled={isDisabledDecrease}>
            <RemoveIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={handleIncrease} disabled={isDisabledIncrease}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Item;
