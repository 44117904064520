import React, { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  useMediaQuery,
  Stack,
  Skeleton,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { getUserInvoiceData } from "API/calls";
import Panel from "components/elements/Panel";
import { Credit } from "components/icons/components/Credit";
import { useAPICreditsPaymentTypes } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import { exists } from "i18next";
import { useSnackbar } from "notistack";
import { useTranslation, Trans } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CreditsPaymentType } from "types/enums";
import { IUserInvoiceData } from "types/types";
import { getColorByMode } from "utils";
import { renderCredits, renderPrice } from "utils/formatter";

import SelectPaymentMethodModal from "./SelectPaymentMethodModal";

interface TableContent {
  id: CreditsPaymentType;
  amount: number;
  priceCZK: number;
  priceCZKWithVAT: number;
  discount?: number;
  cashbackSats?: boolean;
}

const tiersDiscount = {
  [CreditsPaymentType.CREDITS_300_V2]: undefined,
  [CreditsPaymentType.CREDITS_600_V2]: 10,
  [CreditsPaymentType.CREDITS_1800_V2]: 15,
  [CreditsPaymentType.CREDITS_3600_V2]: 20,
  [CreditsPaymentType.CREDITS_THIRD_3600]: 33,
  [CreditsPaymentType.CREDITS_HALF_10000]: 50,
};

const tiersCashbackSats = {
  [CreditsPaymentType.CREDITS_1800_V2]: true,
  [CreditsPaymentType.CREDITS_3600_V2]: true,
  [CreditsPaymentType.CREDITS_THIRD_3600]: true,
};

interface Props {
  buyEnabled?: boolean;
}

const CreditsBundles: React.FC<Props> = ({ buyEnabled, children }) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const [loadingPayment, setLoadingPayment] = useState<CreditsPaymentType | null>(null);
  const [selectPaymentType, setSelectPaymentType] = useState<CreditsPaymentType>();
  const { data } = useAPICreditsPaymentTypes();

  const tiers: TableContent[] = Object.entries(data?.creditsPaymentTypes ?? {})
    .filter(([_, value]) => value.active === true && value.recurring === false)
    .map(([key, value]) => {
      return {
        id: key as CreditsPaymentType,
        priceCZK: value.czkAmountWithoutTax,
        priceCZKWithVAT: value.czkAmountWithTax,
        amount: value.creditsAmount,
        discount: key in tiersDiscount ? tiersDiscount[key as keyof typeof tiersDiscount] : undefined,
        cashbackSats: key in tiersCashbackSats,
      };
    })
    .sort((a, b) => (a ? a.amount : 0) - (b ? b.amount : 0));

  const onClick = async (creditsPaymentType: CreditsPaymentType) => {
    setLoadingPayment(creditsPaymentType);
    try {
      const { invoiceData } = (await getUserInvoiceData()).data as IUserInvoiceData;
      if (invoiceData !== null) {
        setSelectPaymentType(creditsPaymentType);
      } else {
        try {
          await confirm({
            allowClose: false,
            title: t("creditsBuy.confirm.title"),
            description: <Trans i18nKey="creditsBuy.confirm.description" />,
            confirmationText: t("creditsBuy.confirm.button"),
          });
          navigate("/nastaveni");
        } catch (err: any) {
          // silent
        } finally {
          setLoadingPayment(null);
        }
      }
    } catch (err: any) {
      enqueueSnackbar(t("creditsBuyError"), { variant: "error" });
    } finally {
      setLoadingPayment(null);
    }
  };

  function renderButton(creditsPaymentType: CreditsPaymentType, credits: number) {
    return (
      <>
        <LoadingButton
          loading={String(loadingPayment) === String(creditsPaymentType)}
          disabled={loadingPayment !== null}
          variant="contained"
          color="success"
          disableElevation
          onClick={() => onClick(creditsPaymentType)}>
          {t("buy")} {renderCredits(credits)}
        </LoadingButton>
      </>
    );
  }

  return (
    <>
      {isMobile && (
        <>
          {children && <Panel sx={{ mb: 4, p: 3, pb: 2 }}>{children}</Panel>}
          {tiers.map((tier, index) => (
            <Panel sx={{ mb: 4, p: 1 }} key={index}>
              <TableContainer>
                <Table>
                  <TableBody
                    sx={{
                      "tr td": {
                        fontSize: "1em",
                        fontWeight: 400,
                        color: getColorByMode(theme, theme.palette.grey[700], theme.palette.grey[500]),
                        border: 0,
                      },
                      "& > tr td:first-of-type": {
                        fontWeight: 600,
                        color: getColorByMode(theme, theme.palette.grey[700], theme.palette.grey[500]),
                      },
                    }}>
                    <TableRow>
                      <TableCell>{t("amount")}</TableCell>
                      <TableCell align="right">
                        <Box
                          sx={{ fontWeight: 400, display: "inline-flex", justifyContent: "end", alignItems: "center" }}>
                          <strong>{new Intl.NumberFormat("cs-CZ", {}).format(tier.amount)}</strong>
                          <Credit sx={{ marginLeft: "4px" }} />
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Link to="/proc-bitcoin/cashback-saty" style={{ color: theme.palette.primary.main }}>
                          {t("creditsPackageCashbackSatsLabel.title")}
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        {tier.cashbackSats
                          ? <strong><Trans i18nKey={"creditsPackageCashbackSatsLabel.yes"} /> ✅</strong>
                          : "---"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("creditsPackageSubscriptionLabel.title")}</TableCell>
                      <TableCell align="right">
                        {exists("creditsPackageSubscriptionLabel." + tier.id)
                          ? <Trans i18nKey={"creditsPackageSubscriptionLabel." + tier.id} />
                          : "---"}
                      </TableCell>
                    </TableRow>
                    {tier.discount && (
                      <TableRow sx={{ background: theme.palette.success.background }}>
                        <TableCell sx={{ borderRadius: "10px 0 0 10px" }}>{t("discount")}</TableCell>
                        <TableCell align="right" sx={{ borderRadius: "0 10px 10px 0" }}>
                          <Typography
                            color="success.light"
                            variant="body1"
                            component="div"
                            sx={{ fontWeight: 600, display: "flex", alignItems: "center", justifyContent: "end" }}>
                            {t("youSave")} {tier.discount}&nbsp;%
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>{t("price")}</TableCell>
                      <TableCell align="right">
                        <Stack direction="column">
                          <strong>{renderPrice(tier.priceCZKWithVAT)}</strong>
                          {tier.priceCZK && tier.priceCZK !== tier.priceCZKWithVAT && (
                            <Typography variant="caption">
                              {t("withoutTax", { price: renderPrice(tier.priceCZK) })}
                            </Typography>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                    {buyEnabled && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          {renderButton(tier.id, tier.amount)}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Panel>
          ))}
        </>
      )}

      {!isMobile && (
        <>
          {children}
          <TableContainer>
            <Table>
              <TableBody
                sx={{
                  "tr td": {
                    fontSize: "1em",
                    fontWeight: 400,
                    color: getColorByMode(theme, theme.palette.grey[700], theme.palette.grey[500]),
                    border: 0,
                  },
                  "& > tr td:first-of-type": {
                    fontWeight: 600,
                    color: getColorByMode(theme, theme.palette.grey[700], theme.palette.grey[500]),
                  },
                }}>
                <TableRow>
                  <TableCell color="gray" align="left" sx={{ width: "20%" }}>
                    {t("amount")}
                  </TableCell>
                  {tiers.map((tier, index) => (
                    <TableCell align="center" sx={{ width: "20%" }} key={index}>
                      <Typography
                        color="gray"
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: 600, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Box>{new Intl.NumberFormat("cs-CZ", {}).format(tier.amount)}</Box>
                        <Credit color="primary" sx={{ ml: 1, fontSize: 24 }} />
                      </Typography>
                    </TableCell>
                  ))}
                  {tiers.length === 0 && (
                    <TableCell align="center" colSpan={4}>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell color="gray" align="left" sx={{ width: "20%" }}>
                    <Link to="/proc-bitcoin/cashback-saty" style={{ color: theme.palette.primary.main }}>
                      {t("creditsPackageCashbackSatsLabel.title")}
                    </Link>
                  </TableCell>
                  {tiers.map((tier, index) => (
                    <TableCell align="center" key={index}>
                      {tier.cashbackSats
                        ? <strong><Trans i18nKey={"creditsPackageCashbackSatsLabel.yes"} /> ✅</strong>
                        : "---"}
                    </TableCell>
                  ))}
                  {tiers.length === 0 && (
                    <TableCell align="center" colSpan={4}>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell color="gray" align="left" sx={{ width: "20%" }}>
                    {t("creditsPackageSubscriptionLabel.title")}
                  </TableCell>
                  {tiers.map((tier, index) => (
                    <TableCell align="center" key={index}>
                      {exists("creditsPackageSubscriptionLabel." + tier.id)
                        ? <Trans i18nKey={"creditsPackageSubscriptionLabel." + tier.id} />
                        : "---"}
                    </TableCell>
                  ))}
                  {tiers.length === 0 && (
                    <TableCell align="center" colSpan={4}>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow sx={{ background: theme.palette.success.background }}>
                  <TableCell color="gray" align="left" sx={{ width: "20%", borderRadius: "10px 0 0 10px" }}>
                    {t("discount")}
                  </TableCell>
                  {tiers.map((tier, index) => (
                    <TableCell
                      align="center"
                      sx={{ width: "20%", ...(index === tiers.length - 1 && { borderRadius: "0 10px 10px 0" }) }}
                      key={index}>
                      <Typography
                        color="success.light"
                        variant="body1"
                        component="div"
                        sx={{ fontWeight: 600, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {tier.discount && (
                          <>
                            {t("youSave")} {tier.discount}&nbsp;%
                          </>
                        )}
                        {!tier.discount && <>---</>}
                      </Typography>
                    </TableCell>
                  ))}
                  {tiers.length === 0 && (
                    <TableCell align="center" colSpan={4}>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>{t("price")}</TableCell>
                  {tiers.map((tier, index) => (
                    <TableCell align="center" key={index}>
                      <Stack direction="column" alignItems="center">
                        <strong>{renderPrice(tier.priceCZKWithVAT)}</strong>
                        {tier.priceCZK && tier.priceCZK !== tier.priceCZKWithVAT && (
                          <Typography variant="caption">
                            {t("withoutTax", { price: renderPrice(tier.priceCZK) })}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                  ))}
                  {tiers.length === 0 && (
                    <TableCell align="center" colSpan={4}>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
                {buyEnabled && (
                  <TableRow>
                    <TableCell />
                    {tiers.map((tier, index) => (
                      <TableCell align="center" key={index}>
                        {renderButton(tier.id, tier.amount)}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {selectPaymentType && (
        <SelectPaymentMethodModal
          open={Boolean(selectPaymentType)}
          handleClose={() => setSelectPaymentType(undefined)}
          creditsPaymentType={selectPaymentType}
        />
      )}
    </>
  );
};

export default CreditsBundles;
