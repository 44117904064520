import { useEffect, useRef } from "react";

import { useTheme } from "@mui/material";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { createChart, CrosshairMode, IChartApi, IPriceLine, ISeriesApi, Time } from "lightweight-charts";
import { IOHLC } from "types/types";
import { renderLocaleDate, renderNumber } from "utils/formatter";

import IProps, { ICandleData } from "./types";

export const convertOHLCData = (data: IOHLC, priceTransform?: (price: number) => number): ICandleData[] => {
  return data.ohlc.map(({ t, o, h, l, c }) => ({
    time: t as Time,
    open: priceTransform ? priceTransform(o) : o,
    high: priceTransform ? priceTransform(h) : h,
    low: priceTransform ? priceTransform(l) : l,
    close: priceTransform ? priceTransform(c) : c,
  }));
};

const xAxisFormatter = (date: number) =>
  Intl.DateTimeFormat(undefined, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));

const CandleChart = ({
  data,
  priceLines,
  dateWithTime = true,
  pricePrecision = 2,
  priceRange,
  priceMargins = 0.2,
  xAxisWithTime,
  markers,
  range,
  lastValueVisible = true,
}: IProps) => {
  const { palette } = useTheme();
  const { colors } = useMUIThemeModeContext();
  const chartContainerRef = useRef<any>();
  const chart = useRef<IChartApi>();
  const candleSeries = useRef<ISeriesApi<"Candlestick">>();
  const cPriceLines = useRef<(IPriceLine | undefined)[]>();

  useEffect(() => {
    const handleResize = () => {
      chart.current?.applyOptions({ width: chartContainerRef.current.clientWidth });
      chart.current?.timeScale().fitContent();
    };

    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      layout: {
        background: { color: "transparent" },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: colors.borderColor,
      },
      timeScale: {
        borderColor: colors.borderColor,
        tickMarkFormatter: xAxisWithTime ? (time: number) => xAxisFormatter(time * 1000) : undefined,
      },
      localization: {
        timeFormatter: (time: number) => renderLocaleDate(time * 1000, dateWithTime),
        priceFormatter: (price: number) => renderNumber(price, pricePrecision, true),
      },
    });
    chart.current.timeScale().fitContent();

    candleSeries.current = chart.current.addCandlestickSeries({
      lastValueVisible: lastValueVisible,
      priceLineVisible: true,
      priceLineColor: palette.tertiary.main,
      priceFormat: {
        type: "price",
        precision: pricePrecision,
        minMove: pricePrecision > 0 ? undefined : 1,
      },
    });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);

      chart.current?.remove();
    };
  }, [dateWithTime, pricePrecision, xAxisWithTime]);

  useEffect(() => {
    if (candleSeries.current) {
      candleSeries.current.setData(data);
    }
  }, [data]);

  useEffect(() => {
    if (priceRange) {
      candleSeries.current?.applyOptions({ autoscaleInfoProvider: () => ({ priceRange }) });

      // create a margin above and below price range lines
      chart.current?.priceScale("right").applyOptions({ scaleMargins: { bottom: priceMargins, top: priceMargins } });
    }
  }, [priceRange]);

  useEffect(() => {
    if (priceLines && priceLines.length > 0) {
      //REMOVE OLD
      cPriceLines.current?.forEach((priceLine) => priceLine && candleSeries.current?.removePriceLine(priceLine));

      //CREATE NEW
      cPriceLines.current = priceLines.map((line) => candleSeries.current?.createPriceLine(line));
    }
  }, [priceLines]);

  useEffect(() => {
    if (candleSeries.current && markers && markers.length) {
      candleSeries.current.setMarkers(markers);
    }
  }, [markers]);

  useEffect(() => {
    if (chart.current) {
      if (range) {
        chart.current.timeScale().setVisibleRange(range);
      } else {
        // add a small margin on the right of the last candle
        chart.current.timeScale().applyOptions({ rightOffset: 10 });
      }
    }
  }, [range?.from, range?.to]);

  useEffect(() => {
    chart.current?.applyOptions({
      layout: {
        textColor: colors.black,
      },
      grid: {
        vertLines: { color: palette.mode === "light" ? "#D6DCDE" : "#444" },
        horzLines: { color: palette.mode === "light" ? "#D6DCDE" : "#444" },
      },
    });
  }, [palette.mode]);

  return <div style={{ height: "100%" }} ref={chartContainerRef} />;
};

export default CandleChart;
