import imagePremiumHero from "assets/images/premium-hero.png";
import imagePremiumVip from "assets/images/premium-vip.png";
import imagePremium from "assets/images/premium.png";
import { RechargeType } from "types/enums";

export const subscriptionCost = {
  FREE: 0,
  PLUS: 50,
  PREMIUM: 150,
};

export const subscriptionDcaBots = {
  FREE: 2,
  PLUS: 7,
  PREMIUM: 30,
};

export const subscriptionLimitOrders = {
  FREE: 1,
  PLUS: 5,
  PREMIUM: 10,
};

export const subscriptionSingleLimitOrders = {
  FREE: "-",
  PLUS: 10,
  PREMIUM: 30,
};

export const subscriptionMinGridPercentStep = {
  FREE: 2.5,
  PLUS: 2,
  PREMIUM: 1,
};

export const subscriptionDefaultGridPercentStep = {
  FREE: 2.5,
  PLUS: 2,
  PREMIUM: 1.5,
};

export const subscriptionBots = {
  FREE: 1,
  PLUS: 2,
  PREMIUM: 4,
};

export const rechargeOptions = [
  {
    // TODO temp sale - remove and uncomment fields below after sale ends
    type: RechargeType.RECHARGE_125,
    prevType: undefined,
    defaultPrice: 125,

    // type: RechargeType.RECHARGE_150,
    // prevType: RechargeType.RECHARGE_125,
    img: imagePremium,
    profileIcon: undefined,
    // defaultPrice: 150,
    contains: [1, 2, 3],
  },
  {
    type: RechargeType.RECHARGE_250,
    img: imagePremiumVip,
    profileIcon: imagePremiumVip,
    defaultPrice: 250,
    contains: [1, 4, 5, 8, 9],
  },
  {
    type: RechargeType.RECHARGE_500,
    img: imagePremiumHero,
    profileIcon: imagePremiumHero,
    defaultPrice: 500,
    contains: [1, 6, 7, 8, 9, 10, 11],
  },
];
