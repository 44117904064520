import React, { FC, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack, Typography } from "@mui/material";
import { creditsPaymentsVouchers, getUserInvoiceData } from "API/calls";
import Panel from "components/elements/Panel";
import { useAPICreditsPaymentTypes } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreditsPaymentGateway, CreditsPaymentType } from "types/enums";
import { IUserInvoiceData } from "types/types";
import { renderNumber, renderPrice } from "utils/formatter";

import Item from "./Item";
import PaymentMethodModal from "./PaymentMethodModal";

interface IVoucherTypes {
  [key: string]: number;
}

const BuyCreditsVouchers: FC = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useAPICreditsPaymentTypes();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [voucherTypes, setVoucherTypes] = useState<IVoucherTypes>({});
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] = useState<boolean>(false);

  const tiers = Object.entries(data?.creditsPaymentTypes ?? {})
    .filter(([_, value]) => value.active === true && !value.recurring)
    .map(([key, value]) => {
      return {
        id: key as CreditsPaymentType,
        priceCZK: value.czkAmountWithoutTax,
        priceCZKWithVAT: value.czkAmountWithTax,
        amount: value.creditsAmount,
      };
    })
    .sort((a, b) => (a ? a.amount : 0) - (b ? b.amount : 0));

  const getValue = (id: CreditsPaymentType) => voucherTypes[id] ?? 0;
  const handleChange = (id: CreditsPaymentType) => (value: number) => setVoucherTypes((prev) => ({ ...prev, [id]: value }));

  const getTotalPrice = () => {
    const total = tiers.reduce(
      (acc, tier) => {
        const value = getValue(tier.id);
        return {
          priceCZK: acc.priceCZK + value * tier.priceCZK,
          priceCZKWithVAT: acc.priceCZKWithVAT + value * tier.priceCZKWithVAT,
        };
      },
      { priceCZK: 0, priceCZKWithVAT: 0 }
    );

    return { priceCZK: renderPrice(total.priceCZK), priceCZKWithVAT: renderPrice(total.priceCZKWithVAT) };
  };
  const totalCredits = tiers.reduce((acc, tier) => acc + getValue(tier.id) * tier.amount, 0);

  const getTotalPriceContent = () => {
    if (!totalCredits) return null;
    const { priceCZK, priceCZKWithVAT } = getTotalPrice();
    const other = priceCZK !== priceCZKWithVAT ? t("buyCreditsVouchers.total", { priceCZK }) : undefined;

    return (
      <Typography variant="subtitle2" fontWeight={400} pb={1}>
        <Trans
          i18nKey="buyCreditsVouchers.totalWithVAT"
          values={{ priceCZK, priceCZKWithVAT, other, }}
        />
      </Typography>
    );
  };

  const handleClick = async () => {
    setIsProcessing(true);

    try {
      const { invoiceData } = (await getUserInvoiceData()).data as IUserInvoiceData;
      if (invoiceData !== null) {
        setIsPaymentMethodModalOpen(true);
      } else {
        try {
          await confirm({
            allowClose: false,
            title: t("creditsBuy.confirm.title"),
            description: <Trans i18nKey="creditsBuy.confirm.description" />,
            confirmationText: t("creditsBuy.confirm.button"),
          });
          navigate("/nastaveni");
        } catch (err: any) {
          // silent
        } finally {
          setIsProcessing(false);
        }
      }
    } catch (err: any) {
      enqueueSnackbar(t("buyCreditsVouchers.invoiceDataError"), { variant: "error" });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBuy = async (gateway: CreditsPaymentGateway) => {
    try {
      const selectedVoucherTypes = Object.entries(voucherTypes).flatMap(([key, value]) => Array(value).fill(key));

      const { data } = await creditsPaymentsVouchers({ voucherTypes: selectedVoucherTypes, gateway });
      window.location.replace(data?.redirectUrl);

      setIsPaymentMethodModalOpen(false);
      setVoucherTypes({});
    } catch (err: any) {
      enqueueSnackbar(t("buyCreditsVouchers.error"), { variant: "error" });
    }
  };

  return (
    <Panel id={"kupony"} sx={{ mb: 4, p: 3 }}>
      <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
        {t("buyCreditsVouchers.title")}
      </Typography>
      <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 3 }}>
        <Trans i18nKey="buyCreditsVouchers.description" />
      </Typography>
      <Stack spacing={1} maxWidth={"300px"}>
        {tiers.map((tier) => (
          <Item key={tier.id} amount={tier.amount} value={getValue(tier.id)} onChange={handleChange(tier.id)} />
        ))}
      </Stack>
      <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} pt={3}>
        {getTotalPriceContent()}
        <LoadingButton
          loading={isProcessing}
          disabled={!totalCredits}
          variant="contained"
          color="success"
          disableElevation
          onClick={handleClick}>
          {t("buyCreditsVouchers.buy", { credits: renderNumber(totalCredits) })}
        </LoadingButton>
      </Box>
      <Typography variant="subtitle2" fontWeight={400} sx={{ pt: 4 }}>
        <Trans i18nKey="buyCreditsVouchers.infoBelow" />
      </Typography>
      {isPaymentMethodModalOpen && (
        <PaymentMethodModal open={true} onClose={() => setIsPaymentMethodModalOpen(false)} onConfrim={handleBuy} />
      )}
    </Panel>
  );
};

export default BuyCreditsVouchers;
