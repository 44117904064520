import { axiosApiInstance } from "API";
import {
  BadgeState,
  BadgeType,
  CreditsPaymentGateway,
  CreditsPaymentType,
  SubscriptionType,
} from "types/enums";
import {
  IActivation,
  IChangeUserPassword,
  ICompleteRegistration,
  IExchangesCurrencyPairsTicker,
  IInitialAmounts,
  IInvoiceData,
  IPreviewGridBotRequest,
  IRegister,
  IUserEmailPreferences,
  IUserPasswordReset,
} from "types/types";
import { getApiPath } from "utils";

async function post(API: string, data: FormData | object) {
  return await axiosApiInstance.post(getApiPath(API), data);
}

async function put(API: string, data: FormData | object) {
  return await axiosApiInstance.put(getApiPath(API), data);
}

async function get(API: string) {
  return await axiosApiInstance.get(getApiPath(API));
}

async function getBlob(API: string) {
  return await axiosApiInstance.get(getApiPath(API), { responseType: "blob" });
}

async function patch(API: string, data: FormData | object) {
  return await axiosApiInstance.patch(getApiPath(API), data);
}

async function del(API: string, data?: FormData | object) {
  return await axiosApiInstance.delete(getApiPath(API), { data: data });
}

export async function register({ email, password, language, affBy }: IRegister) {
  return await post("/api/register", {
    email,
    password,
    language,
    affBy,
  });
}

export async function registerWithEmailOnly(email: string, affBy?: string) {
  return await post("/api/register-with-email-only", {
    email,
    affBy,
  });
}

export async function registerAnycoinAccount() {
  return await post("/api/register-anycoin-account", {});
}

export async function changeUserPassword({ oldPassword, newPassword }: IChangeUserPassword) {
  return await post("/api/change-user-password", {
    oldPassword,
    newPassword,
  });
}

export async function emailVerification(token: string) {
  await post("/api/email-verification", {
    token,
  });
}

export async function resendVerificationEmail(oldToken: string) {
  await post("/api/resend-verification-email", {
    oldToken,
  });
}

export async function login(username: string, password: string, rememberMe: boolean) {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("remember-me", String(rememberMe));

  return await post("/api/login", formData);
}

export async function logout() {
  await get("/api/logout");
}

export async function createDcaBot(body: object) {
  return await post("/api/dca-bots", body);
}

export async function getCurrencyPairsTicker({ exchangeType, currencyPair }: IExchangesCurrencyPairsTicker) {
  return await get(`/api/exchanges/${exchangeType}/currency-pairs/${currencyPair}/ticker`);
}

export async function updateDcaBotEnabled(id: string, body: object) {
  await patch(`/api/dca-bots/${id}/enabled`, body);
}

export async function deleteDcaBot(id: string) {
  await del(`/api/dca-bots/${id}`);
}

export async function runDcaBot(id: string) {
  return await post(`/api/dca-bots/${id}/run`, {});
}

export async function updateExchangeConnections(id: string, body: object) {
  await patch(`/api/exchange-connections/${id}`, body);
}

export async function createExchangeConnections(body: object) {
  return await post("/api/exchange-connections", body);
}

export async function getExchangeConnectionsBalancesCurrencies(id: string, currencies: string) {
  return await get(`/api/exchange-connections/${id}/balances?currencies=${currencies}`);
}

export async function updateDcaBot(id: string, body: object) {
  await patch(`/api/dca-bots/${id}`, body);
}

export async function updateDcaBotTargetValuePrice(id: string, targetValuePrice: number | null) {
  await patch(`/api/dca-bots/${id}/target-value-price`, {
    targetValuePrice,
  });
}

export async function subscription(subscription: SubscriptionType) {
  return await patch("/api/subscriptions", { type: subscription });
}

export async function badgesPATCH(badgeType: BadgeType, state: BadgeState) {
  await patch(`/api/badges/${badgeType}`, { state });
}

export async function creditsPayments(type: CreditsPaymentType, gateway: CreditsPaymentGateway) {
  return await post("/api/credits-payments", { type, gateway });
}

export async function getCreditsPaymentsTransactionInvoice(id: string) {
  return await getBlob(`/api/credits-payments/transaction/${id}/invoice`);
}

export async function deleteExchangeConnections(id: string) {
  return await del(`/api/exchange-connections/${id}`);
}

export async function getUserData() {
  return await get(`/api/user-data`);
}

export async function instantBuyOrder(id: string, value: number) {
  return await post(`/api/dca-bots/${id}/instant-buy-order`, {
    value,
  });
}

export async function instantSellOrder(id: string, amount: number) {
  return await post(`/api/dca-bots/${id}/instant-sell-order`, {
    amount
  });
}

export async function independentLimitBuyOrder(id: string, value: number, price: number,) {
  return await post(`/api/dca-bots/${id}/independent-limit-buy-order`, {
    value,
    price,
  });
}

export async function independentLimitSellOrder(id: string, amount: number, price: number,) {
  return await post(`/api/dca-bots/${id}/independent-limit-sell-order`, {
    amount,
    price,
  });
}

export async function enableMFA() {
  return await post(`/api/mfa`, {});
}

export async function confirmMFA(otp: string) {
  return await put(`/api/mfa`, { otp });
}

export async function disableMFA(otp: string) {
  return await del(`/api/mfa`, { otp });
}

export async function loginMFA(otp: string) {
  return await post(`/api/auth/mfa-token`, { otp });
}

export async function requestUserPasswordReset(email: string) {
  return await post(`/api/request-user-password-reset`, { email });
}

export async function getUserPasswordReset(token: string) {
  return await get(`/api/user-password-reset?token=${token}`);
}

export async function userPasswordReset(paylod: IUserPasswordReset) {
  return await post(`/api/user-password-reset`, paylod);
}

export async function creditsVouchersClaim(code: string) {
  return await patch(`/api/credits-vouchers/${code}`, { creditsVoucherStatus: "CLAIMED" });
}

export async function deleteUser() {
  return await del(`/api/user`);
}

export async function updateInitialAmounts(payload: IInitialAmounts) {
  return await patch(`/api/dca-bots/${payload.id}/initial-amounts`, {
    initialBaseCurrencyAmount: payload.initialBaseCurrencyAmount,
    initialCounterCurrencyAmount: payload.initialCounterCurrencyAmount,
  });
}

export async function getUserInvoiceData() {
  return await get(`/api/user/invoice-data`);
}

export async function updateUserInvoiceData(payload: IInvoiceData) {
  return await patch(`/api/user/invoice-data`, { invoiceData: payload });
}

export async function cancelRun(dcaBotId: string, runId: string) {
  return await del(`/api/dca-bots/${dcaBotId}/runs/${runId}`);
}

export async function completeRegistrationWithDcaBot(payload: IActivation) {
  return await post("/api/complete-registration-with-dca-bot", payload);
}

export async function completeRegistrationWithEmailOnly(payload: ICompleteRegistration) {
  return await post("/api/complete-registration-with-email-only", payload);
}

export async function createWithdrawalConnections(body: object) {
  return await post("/api/withdrawal-connections", body);
}

export async function createWithdrawalConnectionsFromExchangeConnection(body: object) {
  return await post("/api/withdrawal-connections/from-exchange-connection", body);
}

export async function enabledWithdrawalConnection(id: string, enabled: boolean) {
  return await patch(`/api/withdrawal-connections/${id}/enabled`, { enabled });
}

export async function deleteWithdrawalConnection(id: string) {
  return await del(`/api/withdrawal-connections/${id}`);
}

export async function editWithdrawalConnection(id: string, body: object) {
  return await patch(`/api/withdrawal-connections/${id}`, body);
}

export async function createGridBot(body: object) {
  return await post("/api/bots/grid", body);
}

export async function updateGridBot(id: string, body: object) {
  return await patch(`/api/bots/grid/${id}`, body);
}

export async function deleteBot(id: string) {
  await del(`/api/bots/${id}`);
}

export async function updateBotEnabled(id: string, body: object) {
  await patch(`/api/bots/${id}/enabled`, body);
}

export async function previewGridBot(body: IPreviewGridBotRequest) {
  return await post("/api/bots/grid/preview", body);
}

export async function getUserEmailPreferences() {
  return await get(`/api/user-email-preferences`);
}

export async function putUserEmailPreferences(preferencesId: string, body: IUserEmailPreferences) {
  return await put(`/api/user-email-preferences/${preferencesId}`, body);
}

export async function updateBotLabel(id: string, body: object) {
  await patch(`/api/bots/${id}/label`, body);
}

export async function updateDcaBotLabel(id: string, body: object) {
  await patch(`/api/dca-bots/${id}/label`, body);
}

export async function getGridBotBacktest(body: object) {
  return await post("/api/bots/grid/backtest", body);
}

export async function cancelCreditsRecharge(id?: string) {
  await del("/api/credits-recharge" + (id ? `/${id}` : ""));
}

export async function createCreditsRecharge(type: string, gateway = CreditsPaymentGateway.STRIPE) {
  return await post("/api/credits-recharge", { type, gateway });
}

export async function getCreditsRecharge() {
  return await get(`/api/credits-recharge`);
}

export async function getExchangeWithdrawalFee(exchangeEnum: string, currency = "BTC") {
  return await get(`/api/exchanges/${exchangeEnum}/currency/${currency}/withdrawal-fee`);
}

export async function getDcaBotsPreview(body: object) {
  return await post("/api/dca-bots/preview", body);
}

export async function previewDynamicDcaStrategy(body: object) {
  return await post("/api/dca-bots/preview-dynamic-dca-strategy", body);
}

export async function dcaBotsBacktest (body: object) {
  return await post("/api/dca-bots/backtest", body);
}

export async function dcaBotsCreateManualOrder(id: string, body: object) {
  return await post(`/api/dca-bots/${id}/manual-orders`, body);
}

export async function dcaBotsDeleteManualOrder(id: string, dcaBotRunId: string) {
  return await del(`/api/dca-bots/${id}/manual-orders/${dcaBotRunId}`);
}

export async function dcaBotsUpdateManualOrder(id: string, dcaBotRunId: string, body: object) {
  return await put(`/api/dca-bots/${id}/manual-orders/${dcaBotRunId}`, body);
}

export async function creditsPaymentsVouchers(body: object) {
  return await post("/api/credits-payments/vouchers", body);
}
