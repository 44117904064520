import { Grid } from "@mui/material";
import Modal from "components/elements/Modal";
import { AVAILABLE_PAYMENT_METHODS, PaymentBox } from "components/modules/CreditsBundles/SelectPaymentMethodModal";
import { useTranslation } from "react-i18next";
import { CreditsPaymentGateway } from "types/enums";

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfrim: (gateway: CreditsPaymentGateway) => void;
}

const PaymentMethodModal = ({ open, onClose, onConfrim }: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={t("creditsBuy.selectPaymentMethod.title")}
      withCloseButton
      disableCancelButton>
      <Grid container spacing={{ xs: 0.5, sm: 1, md: 2 }}>
        {AVAILABLE_PAYMENT_METHODS.map(({ gateway, image, title }) => (
          <Grid item xs={12} sm={4} md={4} key={gateway}>
            <PaymentBox onSelect={() => onConfrim(gateway)} buttonText={t(title)}>
              {image}
            </PaymentBox>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};

export default PaymentMethodModal;
